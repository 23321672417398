.video {
  margin-bottom: 40px;

  iframe, img {
    display: block;
    width: 100%;
  }
}

.video--has-cover-image {
  iframe {
    display: none;
  }
}

.video__cover-image {
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &:hover {
    .video__cover-shade {
      opacity: 1;
    }
  }
}

.video__cover-shade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba($black, .75);
  opacity: 0;
  transition: opacity $transition;
}