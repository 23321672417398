.wallspace-artists {
  @include dev-border(red);
  margin: -2rem 0 2rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.wallspace-artist {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;

  img {
    display: block;
    width: 120px;
    height: 120px;
  }
}

.wallspace-artist__name {
  margin-top: 1.2rem;
}

@media (min-width: map-get($breakpoints, xl) + 1px) and (max-width: 1470px) {
  .wallspace-artists {
    grid-column: 2 / 3;
  }
}

@media (min-width: 1470px) {
  .wallspace-artists {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 200px;
    align-items: flex-end;
  }

  .wallspace-artist {
    img {
      width: 200px;
      height: 200px;
    }
  }

  // .wallspace-artists + .content-container__inner {
  //   border: 1px solid red;
  // }
}