.image-gallery,
.image-gallery__triptych {
  display: grid;
  gap: 1.6rem;
}

.image-gallery + .image-gallery {
  margin-top: 1.6rem;
}

.image-gallery__cell {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.7);
    transition: $transition;
    opacity: 0;
    pointer-events: none;
    background-image: url("/dist/images/icons/search-plus-regular.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px auto;
  }

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

@media (min-width: map-get($breakpoints, md) + 1px) {
  .image-gallery {
    grid-template-columns: repeat(2, 1fr);

    .load-more {
      grid-column: span 2;
    }
  }

  @supports (object-fit: cover) {
    .image-gallery__cell img {
      object-fit: cover;
      height: 100%;
    }
  }

  .image-gallery__triptych {
    grid-column: 1 / -1;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .image-gallery__triptych--odd {
    grid-template-areas:
      "large large large small-1"
      "large large large small-2";

    .image-gallery__cell--1 {
      grid-area: large;
    }

    .image-gallery__cell--2 {
      grid-area: small-1;
    }

    .image-gallery__cell--3 {
      grid-area: small-2;
    }
  }

  .image-gallery__triptych--even {
    grid-template-areas:
      "small-1 large large large"
      "small-2 large large large";

    .image-gallery__cell--1 {
      grid-area: small-1;
    }

    .image-gallery__cell--2 {
      grid-area: small-2;
    }

    .image-gallery__cell--3 {
      grid-area: large;
    }
  }
}
