$dev-mode: false;

// Colours
$black: #090f0a;
$navy: #181e39;
$orange: #da7800;
$bright-orange: #fb8000;
$teal: #007579;
$green: #0f4c24;
$mustard: #bfb900;
$dust: #e7fbea;
$plum: #680036;
$white: #fff;

$breakpoints: (
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$content-width--sm: 600px;
$content-width--md: 730px;
$content-width--lg: 935px;

$base__font-family: "Spectral", serif;
$handwritten__font-family: "SSA-Hand", cursive;
$handwritten__text-transform: uppercase;

$transition: 0.25s ease;