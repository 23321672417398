table {
  width: 100%;
  border-spacing: 0;
  font-size: 1.6rem;
}

thead {
  text-transform: uppercase;
  font-weight: 700;

  tr:last-child th {
  border-bottom: 1px solid rgba($black, 0.2);
  }
}

th, td {
  padding: 0.5em 0;
}