@import "site-logo";
@import "main-nav";
@import "social-links";

.site-header {
  @include dev-border;
  grid-column: 1 / -1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.site-header__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .site-header {
    grid-column: 1 / 2;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  .site-header__container {
    position: sticky;
    top: 30px;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
}