.homepage__content {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  justify-content: end;
  @include dev-border;
  max-width: 1700px;
  height: 100%;
}

.homepage__why-statement {
  @include dev-border;
  justify-self: end;
  width: 100%;
  max-width: 1040px;
  margin: 20px 0;
  text-align: right;
}

.homepage__hero {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.homepage__hero-image-wrapper {
  img {
    display: block;
  }

  .video__cover-shade {
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &.homepage__hero-image-wrapper--has-video:hover {
    cursor: pointer;
    .video__cover-shade {
      opacity: 1;
    }
  }
}

.homepage__hero-video {
  display: none; // Reveal with JS
  width: 100%;
  height: auto;

  @supports (aspect-ratio: 16 / 9) {
    aspect-ratio: 16 / 9;
  }
}


@media (min-width: map-get($breakpoints, xl) + 1px) {
  .homepage__content {
    height: calc(100vh - 130px);
  }

  .homepage__hero {
    @include dev-border(blue);

    img {
      width: auto;
      height: 100%;
    }
  }

  .homepage__hero-image-wrapper {
    @include dev-border(green);
    width: auto;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .homepage__hero-video {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 768px; // No idea
    height: 100%;
    max-height: 585px;

    @supports (aspect-ratio: 16 / 9) {
      width: auto;
      max-width: auto;
      max-height: auto;
    }
  }

  .homepage__why-statement {
    margin: 30px 0;
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) and (min-height: 800px) {
  .homepage__content {
    height: calc(100vh - 295px);
  }
}
