// Reset
button {
  outline: 0;
  border: 0;
  background: none;
  border-radius: 0;
}

.button,
button {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  padding: 1.2rem 1.5rem 1rem;
  line-height: 1;
  font-family: $handwritten__font-family;
  text-transform: $handwritten__text-transform;
  cursor: pointer;
}

.load-more {
  font-size: 1.6rem;
  margin: 1.6rem auto 0;
}
