.site-footer {
  @include dev-border(blue);
  display: flex;
  flex-direction: column;

  img {
    display: block;
  }
}

.footer-nav {
  ul {
    margin: 0 0 1.5rem;
    padding: 0;
    list-style: none;
    display: flex;
    font-size: 1.4rem;
  }
}

.footer-legal {
  font-size: 1.4rem;
  margin: 0 0 1.5rem;
}

.footer-logos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;

  img:not(:first-child) {
    margin: 1.6rem 0 0;
  }
}

@media (min-width: 660px) {
  .site-footer {
    flex-direction: row;
    align-items: center;
    height: 40px; // Enforce a height here
  }

  .footer-nav {
    flex-shrink: 0;
    ul {
      margin: 0 1.6rem 0 0;
      flex-shrink: 0;
    }
  }

  .footer-legal {
    flex-grow: 1;
    margin: 1rem 0;
  }

  .footer-logos {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;

    img:not(:first-child) {
      margin: 0 0 0 1.6rem;
    }
  }
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .site-footer {
    grid-column: 1 / 3;
    grid-row: 2;
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) {
  .site-footer {
    grid-column: 1 / -1;
  }
}
