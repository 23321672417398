*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

main {
  flex-grow: 1;
}

.visually-hidden {
  position: absolute;
  left: -9999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

iframe,
img,
video,
svg {
  max-width: 100%;
}

img {
  height: auto;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
