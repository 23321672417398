.download-pdf {
  margin-bottom: 40px;
  background-color: $white;
  border: 1px solid $orange;
  color: $black;
  border-radius: 0.4rem;
  padding: 2rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  &:hover {
    .download-pdf__label {
      text-decoration: underline;
    }
  }
}

.download-pdf__icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: $orange;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;;

  svg {
    width: 20px;
    height: auto;
    color: $white;
  }
}

.download-pdf__contents {
  display: flex;
  flex-direction: column;
}

.download-pdf__instruction {
  margin-top: .5rem;
  font-size: 1.6rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $black;
}

@media (min-width: map-get($breakpoints, md)) {
  .download-pdf {
    flex-direction: row;
  }

  .download-pdf__icon {
    margin: 0 1rem 0 0;
  }
}