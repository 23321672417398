.entry-listing,
.gallery-listing {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.gallery-listing .caption {
  margin-top: 0;
}

.entry-listing-item,
.gallery-listing-item {
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}

.standfirst + .entry-listing,
.standfirst + .gallery-listing {
  margin-top: 3rem;
}

.entry-listing-item__hero img {
  display: block;
  width: 100%;
}

.entry-listing-item__copy {
  h2 {
    font-size: 2.4rem;
    a {
      text-decoration: none;
    }
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .entry-listing-item {
    grid-template-columns: 58% 1fr;
  }
}