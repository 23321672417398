input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select,
#card-element {
  width: 100%;
  padding: 8px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: .25em;
}

select {
  height: 46px;
  padding: 0 8px;
}

input[type="submit"],
button[type="submit"] {
  border: 0;
  margin-top: 1em;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.field-row {
  margin-bottom: 2.4rem;
  display: flex;
  flex-wrap: wrap;
}

.form-actions {
  margin-top: 4rem;
}

label {
  display: block;
  font-size: .75em;
  margin-bottom: .5rem;
  width: 100%;
  font-weight: bold;
}

.field-instruction {
  font-size: .75em;
  margin-top: -.25rem;
  margin-bottom: .5rem;
  width: 100%;
  opacity: .8;
}

.mc-field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.alert:not(:empty) {
  display: block;
  margin-bottom: 1.6rem;
  font-weight: bold;
  padding: .8rem;

  &.error {
    background-color: lighten(red, 40%);
  }
}

.error,
.card-errors {
  color: red;
}

.card-errors {
  margin-top: 8px;
}

.mailchimp-form {
  // margin-top: 3em;
}

.donation-widget {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
  margin: 0 0 4rem 0;

  input[type="radio"] {
    display: none;
  }

  label {
    margin: 0;
    display: block;
    text-align: center;
    border-radius: 0.4rem;
    padding: 1.2rem 1.5rem 1rem;
    font-size: 3.2rem;
    font-weight: bold;
    border: 2px solid $bright-orange;
    color: $bright-orange;
    cursor: pointer;
    transition: all $transition;

    &.active {
      background-color: $bright-orange;
      color: $white;
    }
  }
}

.donation-widget-actions {
  display: flex;
  max-width: 400px;
  position: relative;

  .button {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &:before {
    content: "£";
    display: block;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  input {
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 24px;
  }
}

.choice-row {
  display: flex;
  align-items: flex-start;

  input {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: .8rem;
    margin-top: .5rem;
  }

  label {
    flex-grow: 1;
  }
}

.field-row--gift-aid {
  margin: 4rem 0;

  img {
    display: block;
    margin-bottom: 1.4rem;
  }
}

.stripe-payment-intents-form {
  width: 100% !important;
}

.card-holder {
  legend {
    @extend label;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -10px;
    margin-bottom: 2.4rem;

    > div {
      flex: 1;
      margin: 10px;
    }
  }
}

.StripeElement {
  padding: 8px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: .25em;
}