.site-logo {
  display: inline-block;
  width: auto;
  height: 90px;

  img {
    display: block;
    width: auto;
    height: 100%;
  }
}

@media (min-width: map-get($breakpoints, md) + 1px) {
  .site-logo {
    height: 110px;
  }
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .site-logo {
    height: 165px;
  }
}