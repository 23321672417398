body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  @include dev-border;
  flex-grow: 1;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto;
}

main {
  @include dev-border(rebeccapurple);
  grid-column: 1 / -1;
}

.content-group {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.matrix-block {
  margin-bottom: 2rem;
}

.lc--nbm > *:last-child {
  margin-bottom: 0;
}

.fc--ntm > *:first-child {
  margin-top: 0;
}

.content-width--sm {
  width: 100%;
  max-width: $content-width--sm;
}

.content-width--md {
  width: 100%;
  max-width: $content-width--md;
}

.content-width--lg {
  width: 100%;
  max-width: $content-width--lg;
}

.content-container--wallspace {
  position: relative;
}

@media (min-width: map-get($breakpoints, lg) + 1) {
  body {
    padding: 30px;
  }

  .content-wrapper {
    gap: 30px;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
  }

  main {
    grid-column: 2 / -1;
  }

  .content-group {
    margin-bottom: 8rem;
  }

  .matrix-block {
    margin-bottom: 4rem;
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) {
  main {
    grid-column: 2 / 3;
  }

  .content-container {
    @include dev-border;
    display: grid;
    grid-template-columns: 10% minmax(auto, 1040px) 1fr;
  }

  .content-container--full {
    @include dev-border;
    display: grid;
    grid-template-columns: 10% minmax(auto, 1600px) 1fr;
  }

  .content-container__inner {
    @include dev-border(blue);
    grid-column: 2 / 3;
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) and (min-height: 800px) {
  main.top-pad {
    padding-top: 165px; // Align vertically with end of logo
  }
}
