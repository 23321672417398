@font-face {
  font-family: "SSA-Hand";
  src: url("/dist/fonts/SSA-Hand.woff2") format("woff2"), url("/dist/fonts/SSA-Hand.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
  font-family: $base__font-family;
  font-size: 62.5%;
}

body {
  font-size: 1.8rem;
  line-height: 1.4;
}

.small {
  font-size: 70%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}

p {
  margin-top: 0;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-family: $handwritten__font-family;
  text-transform: $handwritten__text-transform;
}

.text-align--right {
  text-align: right;
}

.caption {
  font-size: 1.6rem;
  width: 100%;
  max-width: 600px;
  margin-top: 1.6rem;

  p {
    margin: 0;
  }
}

.caption--right {
  align-self: flex-end;
}

.standfirst {
  font-size: 1.5em;
}

.contact-email {
  word-break: break-all;
}

@media (min-width: map-get($breakpoints, md) + 1px) {
  body {
    font-size: 2.4rem;
  }

  h1 {
    font-size: 3.6rem;
  }
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  h1 {
    font-size: 4.8rem;
  }
}
