.blockquote {
  @include dev-border(red);
  margin-bottom: 40px;

  svg {
    width: 2.4rem;
    height: auto;
    margin-bottom: 0.8rem;
  }

  blockquote {
    margin: 0;
    padding: 0;
    font-family: $handwritten__font-family;
    text-transform: $handwritten__text-transform;
  }

  span {
    margin-top: 0.8rem;
    display: block;
    font-size: 1.8rem;
  }
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .blockquote {
    svg {
      width: 3.6rem;
    }

    blockquote {
      font-size: 3.6rem;
    }
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) {
  .blockquote {
    svg {
      width: auto;
      height: 5rem;
    }

    blockquote {
      font-size: 4.4rem;
    }
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) and (min-height: 800px) {
  .content-container__inner > .blockquote:first-child {
    margin-top: -5rem; // Hoist the speech marks up
  }
}
