.body--about .content-container__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#aboutImage1 {
  align-self: flex-end;
}

.about-image {
  display: block;
  margin: 3rem 0 4rem;
}

.about-content-group--3 {
  width: 100%;
  max-width: 782px;
  align-self: flex-end;

  .about-image {
    margin-top: 2rem;
  }
}

.team-members {
  margin: 3rem 0 0;
  padding: 3rem 0;
  align-self: stretch;

  > h3 {
    margin-top: 0;
    font-size: 1.6rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
}

.team-members__list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.team-member {
  padding: 3rem 0;
  border-top: 1px solid rgba($black, 0.1);
  display: flex;
  flex-direction: column;
}

.team-member__contents {
  flex-grow: 1;
}

.team-member__headshot {
  background-color: rgba($black, 0.1);
  width: 100%;
  min-height: 168px;
  margin: 0 0 1.6rem;
}

.team-member__headshot-image {
  display: block;
  width: 100%;
}

.team-member__name {
  display: block;
  font-size: 2.4rem;
  font-family: $handwritten__font-family;
  text-transform: $handwritten__text-transform;
}

.team-member__bio {
  margin-top: 0.8rem;
  font-size: 1.8rem;
}

.additional-people {
  width: 100%;
}

.additional-people__list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
}

.additional-person {
  width: 100%;
  padding: 2rem 0;
  border-top: 1px solid rgba($black, 0.1);
  display: flex;
  flex-direction: column;
}

.additional-person__name {
  font-size: 2rem;
  font-family: $handwritten__font-family;
  text-transform: $handwritten__text-transform;
}

@media (min-width: 600px) {
  .team-member {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .team-member__headshot {
    margin: 0 2rem 0 0;
    max-width: 300px;
  }

  .team-member__contents {
    text-align: right;
  }
}

@media (min-width: map-get($breakpoints, md) + 1) {
  .about-image {
    margin: 8rem 0 6rem;
  }

  .team-members {
    margin: 6rem 0 0;
    padding: 6rem 0;
  }
}
