.social-links {
  grid-column: 2 / -1;
  grid-row: 3;
  @include dev-border(green);
  display: flex;
  align-items: center;

  li:not(:last-child) {
    margin: 0 1.6rem 0 0;
  }

  a {
    display: flex;
    height: 27px;
    text-decoration: none;
    @include dev-border(red);
  }
}

.social-links__list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .social-links {
    grid-column: 3 / -1;
    grid-row: 2;
  }
}

@media (min-width: map-get($breakpoints, xl) + 1px) {
  .social-links {
    grid-row: 1;
    align-items: flex-start;
  }

  .social-links__list {
    flex-direction: column;
    position: sticky;
    top: 30px;

    li:not(:last-child) {
      margin: 0 0 1.6rem;
    }
  }
}
