.main-nav {
  @include dev-border(green);

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: $handwritten__font-family;
    text-transform: $handwritten__text-transform;
  }

  li {
    display: block;
  }

  a {
    text-decoration: none;
    display: block;
    line-height: 1;
  }
}

#menuButton {
  font-size: 1.6rem;
  z-index: 3;
  position: fixed;
  top: 20px;
  right: 20px;
}

// Full screen
@media (max-width: map-get($breakpoints, md)) {
  .main-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    z-index: 2;

    ul {
      flex-direction: column;
      text-align: center;
    }

    li:not(:last-child) {
      margin: 0 0 1rem;
    }

    a {
      font-size: 4rem;
    }
  }
}

@media (min-width: map-get($breakpoints, md) + 1px) {
  #menuButton {
    display: none;
  }

  .main-nav {
    display: block !important;

    li:not(:last-child) {
      margin: 0 1.2rem 0 0;
    }

    a {
      font-size: 1.8rem;
    }
  }
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .main-nav {
    ul {
      flex-direction: column;
      text-align: right;
    }

    li:not(:last-child) {
      margin: 0 0 1.2rem;
    }

    a {
      font-size: 3.2rem;
    }
  }
}
