@mixin flavour (
  $body__bg,
  $body__colour,
  $logo__version,
  $button__bg,
  $button__colour,
  $link__colour,
  $mobile-nav-btn__bg,
  $mobile-nav-btn__colour,
  $mobile-nav__bg,
  $mobile-nav-item__colour,
  $nav-item__colour,
  $social__colour,
  $quote__colour,
  $download-pdf__colour
) {

  // Body
  background-color: $body__bg;
  color: $body__colour;

  // Logo(s)
  @if $logo__version == "dark" {
    .site-logo--light,
    .footer-logo--light {
      display: none;
    }
  } @else {
    .site-logo--dark,
    .footer-logo--dark {
      display: none;
    }
  }

  // Buttons
  .button, button {
    background-color: $button__bg;
    color: $button__colour;

    &:hover {
      background-color: darken($button__bg, 5%);
      color: $button__colour;
    }
  }

  // Links
  a {
    color: $link__colour;

    &:hover {
      color: darken($link__colour, 10%);
    }
  }

  // Mobile nav btn
  #menuButton {
    background-color: $mobile-nav-btn__bg;
    color: $mobile-nav-btn__colour;

    &[aria-expanded] {
      background-color: $mobile-nav-item__colour;
      color: $mobile-nav__bg;
    }
  }

  // Mobile nav
  @media (max-width: map-get($breakpoints, md)) {
    .main-nav {
      background-color: $mobile-nav__bg;

      a {
        color: $mobile-nav-item__colour;
      }
    }
  }

  // Desktop nav
  @media (min-width: map-get($breakpoints, md) + 1px) {
    .main-nav a {
      color: $nav-item__colour;

      &:hover {
        color: darken($nav-item__colour, 10%);
      }
    }
  }

  // Social
  .social-links a {
    color: $social__colour;

    &:hover {
      color: darken($social__colour, 10%);
    }
  }

  // Blockquote
  .blockquote path {
    fill: $quote__colour;
  }

  // Listing
  .entry-listing-item,
  .gallery-listing-item {
    border-top: 1px solid rgba($body__colour, .2);
  }

  // Download pdf matrix block
  .download-pdf {
    border-color: $download-pdf__colour;
  }

  .download-pdf__icon {
    background-color: $download-pdf__colour;
  }

  .download-pdf__label {
    color: $download-pdf__colour;
  }
}

body.flavour--dust {
  @include flavour (
    $body__bg: $dust,
    $body__colour: $black,
    $logo__version: "dark",
    $button__bg: $orange,
    $button__colour: $black,
    $link__colour: $bright-orange,
    $mobile-nav-btn__bg: $orange,
    $mobile-nav-btn__colour: $black,
    $mobile-nav__bg: $orange,
    $mobile-nav-item__colour: $black,
    $nav-item__colour: $bright-orange,
    $social__colour: black,
    $quote__colour: $orange,
    $download-pdf__colour: $orange
  );
}

body.flavour--mustard {
  @include flavour (
    $body__bg: $mustard,
    $body__colour: $black,
    $logo__version: "dark",
    $button__bg: $green,
    $button__colour: $white,
    $link__colour: $green,
    $mobile-nav-btn__bg: $green,
    $mobile-nav-btn__colour: $white,
    $mobile-nav__bg: $green,
    $mobile-nav-item__colour: $white,
    $nav-item__colour: $green,
    $social__colour: $black,
    $quote__colour: $green,
    $download-pdf__colour: $green
  );
}

body.flavour--teal {
  @include flavour (
    $body__bg: $teal  ,
    $body__colour: $white,
    $logo__version: "light",
    $button__bg: darken($teal, 10%),
    $button__colour: $mustard,
    $link__colour: $mustard,
    $mobile-nav-btn__bg: $mustard,
    $mobile-nav-btn__colour: $black,
    $mobile-nav__bg: $mustard,
    $mobile-nav-item__colour: $black,
    $nav-item__colour: $mustard,
    $social__colour: $mustard,
    $quote__colour: $mustard,
    $download-pdf__colour: $teal
  );
}

body.flavour--orange {
  @include flavour (
    $body__bg: $orange,
    $body__colour: $black,
    $logo__version: "dark",
    $button__bg: $teal,
    $button__colour: $dust,
    $link__colour: $dust,
    $mobile-nav-btn__bg: $teal,
    $mobile-nav-btn__colour: $dust,
    $mobile-nav__bg: $teal,
    $mobile-nav-item__colour: $dust,
    $nav-item__colour: $dust,
    $social__colour: $dust,
    $quote__colour: $dust,
    $download-pdf__colour: $orange
  );
}

body.flavour--green {
  @include flavour (
    $body__bg: $green,
    $body__colour: $white,
    $logo__version: "light",
    $button__bg: $mustard,
    $button__colour: $black,
    $link__colour: $mustard,
    $mobile-nav-btn__bg: $mustard,
    $mobile-nav-btn__colour: $black,
    $mobile-nav__bg: $mustard,
    $mobile-nav-item__colour: $black,
    $nav-item__colour: $mustard,
    $social__colour: $mustard,
    $quote__colour: $mustard,
    $download-pdf__colour: $mustard
  );
}

body.flavour--plum {
  @include flavour (
    $body__bg: $plum,
    $body__colour: $white,
    $logo__version: "light",
    $button__bg: $mustard,
    $button__colour: $plum,
    $link__colour: $mustard,
    $mobile-nav-btn__bg: $mustard,
    $mobile-nav-btn__colour: $plum,
    $mobile-nav__bg: $mustard,
    $mobile-nav-item__colour: $plum,
    $nav-item__colour: $mustard,
    $social__colour: $mustard,
    $quote__colour: $mustard,
    $download-pdf__colour: $mustard
  )
}